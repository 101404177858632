import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/layout/seo"

import Layout from "../../components/layout/layout"
import ProductPage from "../../components/product/product"

import img1 from "../../images/solar-home-lighting-system/8(1).jpg"
import img2 from "../../images/solar-home-lighting-system/8(2).jpg"
import img3 from "../../images/solar-home-lighting-system/8(3).jpg"
import img4 from "../../images/solar-home-lighting-system/8(4).jpg"
import img5 from "../../images/solar-home-lighting-system/10(5).jpg"
import img6 from "../../images/solar-home-lighting-system/10(6).jpg"

import descImg1 from "../../images/solar-home-lighting-system/vasu.png"
import descImg2 from "../../images/solar-home-lighting-system/application.png"
import descImg3 from "../../images/solar-home-lighting-system/benefits.png"

import solarHomeSpecSheet from "../../images/solar-home-lighting-system/specSheet.svg"

const SolarHomeLightingSystem = () => {
  return (
    <Layout home={false}>
      <SEO title="Solar Home Lighting system" />
      <ProductPage
        productTitle="Solar Home Lighting system"
        showcase={
          <>
            <ul>
              <li>
                Microcontroller based maximum power point tracking
                technology(MPPT)
              </li>
              <li>Maintain CC-CV</li>
              <li>
                Charging Kit with USB output to charge mobile, mp3 player and
                Etc.
              </li>
              <li>
                Battery management with Overcharge protection and Low battery
                cut off
              </li>
              <li>Polycrystalline Photovoltaic Solar Panel Module</li>
              <li>
                Dimming Facility- 3 stage dimming facility of luminaries for
                power saving and better running time
              </li>
              <li>
                Running time 5 to 6 hours on full Load Battery low, charging ON
                indication
              </li>
              <li>Eco friendly power backup system</li>
              <li>Pay and go financing model</li>
            </ul>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to="/contact-us"
                style={{
                  backgroundColor: "#203864",
                  marginTop: "1rem",
                  color: "white",
                  textDecoration: "none",
                  padding: "0.5rem 1rem",
                  fontFamily: "Helvetica",
                }}
              >
                Enquire Now
              </Link>
            </div>
          </>
        }
        showcaseImages={[img2, img3, img5, img6]}
        description={
          <div style={{ width: "100%" }}>
            <div>
              <p style={{ fontSize: "1.2rem" }}>
                High Street Lighting provides an ideal Solar Home Lighting
                System which is not only affordable but also efficient and
                effective. It is an eco-friendly product which acts as a source
                of Sustainable energy as well. The solar cells integrated into
                the device turn the energy of the sun into electricity.
              </p>
              <p style={{ fontSize: "1.2rem" }}>
                This power is stored within the lithium-ion battery and used
                whenever vital to light up the room. The proper domestic
                lighting is compact, light-weight, and easy to install. The
                system‘s additives include solar cells, solar battery, charging
                controller, lights, and fans.
              </p>
            </div>

            <div
              className="product-description-heading"
              style={{ marginTop: "2rem" }}
            >
              Pay As You Go
            </div>
            <div className="product-description-heading-underline"></div>
            <div>
              <p style={{ fontSize: "1.2rem" }}>
                Pay-As-You-Go (PAYG), as the name suggests, allows customers to
                buy off-grid solar electricity from a solar panel on a
                pay-as-you-go basis. The software installed within solar panels,
                which facilitates these payments, is referred to as PAYG
                technology. As a result of the technology, solar customers are
                not required to pay heavy down payments on initial technology
                purchases or costly interest rates while repaying debt.
              </p>
            </div>

            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "4rem"
              }}
            >
              <img
                src={descImg1}
                style={{ maxWidth: "80%", maxHeight: "50rem" }}
              />
            </div>
            <div
              className="product-description-heading"
              style={{ marginTop: "2rem" }}
            >
              Applications
            </div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "4rem"
              }}
            >
              <img
                src={descImg2}
                style={{ maxWidth: "80%", maxHeight: "35rem" }}
              />
            </div>
            <div
              className="product-description-heading"
              style={{ marginTop: "2rem" }}
            >
              Benefits of HSL Home Lighting System
            </div>
            <div className="product-description-heading-underline"></div>
            <div
              style={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "4rem"
              }}
            >
              <img
                src={descImg3}
                style={{ maxWidth: "80%", maxHeight: "45rem" }}
              />
            </div>
          </div>
        }
        technicalDetails={
          <div style={{ width: "180%", marginLeft: "-40%" }}>
            <img src={solarHomeSpecSheet} style={{ width: "100%" }} />
          </div>
        }
      />
    </Layout>
  )
}

export default SolarHomeLightingSystem
